import AppStandardButton from "../../components/UI/AppButtons";
import { BookingInformationRow } from "../../components/Layouts/BookingInformation/BookingInformation";
import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { useLocation, useSearchParams } from "react-router-dom";
import { BookingApi } from "../../API/Apis/BookingApi";
import { NexiChargeTransactionDetails } from "../../@types/Booking/nexiTransactionDetails";
import TagManager from "react-gtm-module";
import BookingGetters from "../../store/onlineBooking/getters";
import { Vehicle } from "../../@types/DTO/Vehicles";
import { SelectedVehicleDetails } from "../../@types/Booking/SelectedVehicleDetails";
import { TextResource } from "../../@types/DTO/AppSetting";
import { formatTheme } from "../../config/MuiColorThemes";
import { createTheme } from "@mui/material/styles";
import i18n from "../../config/i18n";
export function WireTransferConfirm() {
  const appSettings = BookingGetters.getAppSettings();
  const redirectWebCheckIn = appSettings && appSettings?.redirectWebCheckIn;
  const HideOneButton =
    appSettings && appSettings?.hideButtonAtConfirmationPage;
  const showResPrintButton = appSettings && appSettings?.showResPrintButton;
  const paymentDetails = BookingGetters.getSelectedVehicleDetails();
  const price = paymentDetails.paymentDetails.price;
  const [searchParams] = useSearchParams();
  const dbId = searchParams.get("dbId");
  const ResId = searchParams.get("ResId");

  const [showGtagManagerEvent, setGtagManagerEvent] = useState(
    appSettings?.gtagManagerEvent || ""
  );
  const [showGTagManagerSendTo, setGTagManagerSendTo] = useState(
    appSettings?.gTagManagerSendTo || ""
  );
  const [showGTagManagerCurrency, setGTagManagerCurrency] = useState(
    appSettings?.gTagManagerCurrency || ""
  );
  const [isTagManagerDataLayerEnabled, setIsTagManagerDataLayerEnabled] =
    useState(false);
  const [showGtagManagerValue, setGtagManagerValue] = useState(false);
  const [showGTagManagerTransactionId, setGTagManagerTransactionId] =
    useState(false);
  const [tagManagerId, setTagManagerId] = useState("");
  const [isTagManagerEnabled, setIsTagManagerEnabled] = useState(false);
  const showPrice = showGtagManagerValue ? price : null;
  const showDbId = showGTagManagerTransactionId ? dbId : null;
  const textResource = BookingGetters.getTextResource();
  const [confirmationPageLabel, setConfirmationPageLabel] =
    useState<String>("");
  useEffect(() => {
    const data: TextResource[] = textResource?.data ?? [];
    const confirmationPageTextData = data.find(
      (item) => item.key === "ConfirmationPageTextLabel"
    );
    setConfirmationPageLabel(
      confirmationPageTextData?.body ||
        t("Congratulations! Your Booking Is Completed")
    );
  });
  const dynamicPalette = formatTheme();
  const dynamicTheme = createTheme(dynamicPalette);
  const primaryColor = dynamicTheme?.palette["primary"]?.main;
  const secondaryColor = dynamicTheme?.palette["secondaryColor"]?.main;
  const confirmationTextColor =
    dynamicTheme?.palette["confirmationTextColor"]?.contrastText;
  useEffect(() => {
    setIsTagManagerDataLayerEnabled(!!appSettings?.enableDataLayer);
    setGTagManagerCurrency(appSettings?.gTagManagerCurrency || "");
    setGTagManagerSendTo(appSettings?.gTagManagerSendTo || "");
    setGtagManagerEvent(appSettings?.gtagManagerEvent || "");
    setGtagManagerValue(!!appSettings?.enableGtagManagerValue);
    setGTagManagerTransactionId(!!appSettings?.enableGTagManagerTranscationId);
    setIsTagManagerEnabled(!!appSettings?.enableTagManager);
    setTagManagerId(appSettings?.tagManagerId || "");
  }, [appSettings]);
  useEffect(() => {
    if (process.env.REACT_APP_COMPANY_CODE === "viaggiare") {
      console.log("Injecting scripts for Viaggiare at confirmation page...");
      const checkOutDate = searchParams.get("PickUpDateTime") || "";
      let formattedDate;
      if (checkOutDate) {
        const date = new Date(checkOutDate);
        formattedDate =
          date.getFullYear() +
          "-" +
          String(date.getMonth() + 1).padStart(2, "0") +
          "-" +
          String(date.getDate()).padStart(2, "0");
      }

      const tempResId = searchParams.get("dbId");
      const currencyCode = appSettings?.defaultCurrency || "EUR";
      const script1 = document.createElement("script");
      script1.type = "text/javascript";
      script1.innerHTML = `
      var orderRef = ${JSON.stringify(
        tempResId + " " + searchParams.get("vehicleName") || ""
      )};
      var payoutCodes = '';
      var offlineCode = '';
      var uid = ${JSON.stringify(searchParams.get("vehicleCategory") || "")};
      var htname = '';
      var merchantID = 4918;
      var pixel = 0;
      var orderValue = ${parseFloat(searchParams.get("importo") || "0")};
      var checkOutDate = ${JSON.stringify(formattedDate || "")};
      var currencyCode = '${currencyCode}';
    `;

      const script2 = document.createElement("script");
      script2.type = "text/javascript";
      script2.src =
        "https://scripts.affilired.com/v2/confirmation.php?merid=4918";

      script2.onload = () => {
        const script3 = document.createElement("script");
        script3.type = "text/javascript";
        script3.innerHTML = `
        recV3(orderValue, orderRef, merchantID, uid, htname, pixel, payoutCodes, offlineCode, checkOutDate, currencyCode);
      `;
        document.body.appendChild(script3);
      };

      document.body.appendChild(script1);
      document.body.appendChild(script2);

      return () => {
        [script1, script2].forEach((script) => {
          if (document.body.contains(script)) document.body.removeChild(script);
        });
      };
    }
  }, []);

  useEffect(() => {
    if (isTagManagerDataLayerEnabled) {
      const tagManagerArgs = {
        dataLayer: {
          event: showGtagManagerEvent,
          send_to: showGTagManagerSendTo,
          value: showPrice,
          currency: showGTagManagerCurrency,
          transaction_id: showDbId,
        },
      };
      TagManager.dataLayer(tagManagerArgs);
    }
  }, [isTagManagerDataLayerEnabled]);
  useEffect(() => {
    if (isTagManagerEnabled && tagManagerId != "") {
      const tagManagerArgs = {
        gtmId: tagManagerId,
      };
      TagManager.initialize(tagManagerArgs); // google tag manager integrated along with the preferences
    }
  }, [tagManagerId, isTagManagerEnabled]);

  const secondPageBackgroundColor =
    appSettings && appSettings["secondPageBackgroundColor"];

  return (
    <section
      className="min-h-[100vh] box-border px-10 md:px-44  flex flex-col border-t-2 border-solid border-grey-300"
      style={{
        background: secondPageBackgroundColor ? secondPageBackgroundColor : "",
      }}
    >
      <header className="grow flex flex-col items-center justif-center gap-4 box-border py-8">
        <img src="/assets/images/success-icon@2x.png" className="h-36 w-36" />
        <h1
          className="text-3xl font-bold py-2"
          style={{
            color: confirmationTextColor
              ? confirmationTextColor
              : secondaryColor,
          }}
        >
          {confirmationPageLabel}
        </h1>
        <p className="font-bold text-black-600"></p>
        <div className="flex flex-col-2 ">
          {" "}
          <AppStandardButton
            href="/embed"
            className={"w-fit py-4 mx-0 md:mx-8"}
            variant="outlined"
            /* color="secondaryColor"*/
            style={{
              borderRadius: "5",
              borderColor: confirmationTextColor
                ? confirmationTextColor
                : primaryColor,
            }}
          >
            {/* Go to Account */}
            <span
              className="normal-case p-1 text-lg"
              style={{
                color: confirmationTextColor
                  ? confirmationTextColor
                  : secondaryColor,
              }}
            >
              {" "}
              Homepage
            </span>
          </AppStandardButton>
          {!redirectWebCheckIn
            ? !HideOneButton && (
                <AppStandardButton
                  href="/embed"
                  className={"w-fit py-4 mx-2 md:mx-8"}
                  /*  color="secondaryColor"*/
                  variant="outlined"
                  style={{
                    borderRadius: "5",
                    borderColor: confirmationTextColor
                      ? confirmationTextColor
                      : primaryColor,
                  }}
                >
                  {/*  Go to Account*/}
                  <span
                    className="normal-case p-1 text-lg"
                    style={{
                      color: confirmationTextColor
                        ? confirmationTextColor
                        : secondaryColor,
                    }}
                  >
                    {" "}
                    Dashboard
                  </span>
                </AppStandardButton>
              )
            : !HideOneButton && (
                <AppStandardButton
                  href={`/booking/web-check-in?lang=${i18n.language}`}
                  className={"w-fit py-4 mx-2 md:mx-8"}
                  /*  color="primary"*/
                  variant="outlined"
                  style={{
                    borderRadius: "5",
                    borderColor: confirmationTextColor
                      ? confirmationTextColor
                      : primaryColor,
                  }}
                >
                  <span
                    className="normal-case p-1 text-lg whitespace-nowrap"
                    style={{
                      color: confirmationTextColor
                        ? confirmationTextColor
                        : secondaryColor,
                    }}
                  >
                    {" "}
                    Web Check-In
                  </span>
                </AppStandardButton>
              )}
          {showResPrintButton && (
            <AppStandardButton
              onClick={() => {
                BookingApi.getResPrint(ResId || "").then((e) => {
                  const url = e?.file;
                  const linkSource = `data:application/pdf;base64,${url}`;
                  const downloadLink = document.createElement("a");
                  const fileName = "file.pdf";
                  downloadLink.href = linkSource;
                  downloadLink.download = fileName;
                  downloadLink.click();
                });
              }}
              className={"w-fit py-4 mx-2 md:mx-8"}
              variant="outlined"
              style={{ borderRadius: "5", color: primaryColor }}
            >
              <span
                className="normal-case p-1 text-lg whitespace-nowrap"
                style={{ color: secondaryColor }}
              >
                {" "}
                Print Reservation
              </span>
            </AppStandardButton>
          )}
        </div>
      </header>
      <main></main>
      <footer className="text-gray-600 box-border pb-32">
        {/* Web Checkin Info */}
        {/* Web Checkin Information */}

        <div className="box-border border-2 border-solid border-grey-200 px-8"></div>
      </footer>
    </section>
  );
}
