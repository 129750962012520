import { AppInputSwitcher } from "../../components/Layouts/AppInputSwitcher/AppInputSwitcher";
import { t } from "i18next";
import AppStandardButton from "../../components/UI/AppButtons";
import { AppStandardMultiSelectCheckBox } from "../../components/UI/AppMultipleSelection";
import React, { useEffect, useState } from "react";
import { BookingApi } from "../../API/Apis/BookingApi";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import BookingGetters from "../../store/onlineBooking/getters";
import { TextResource } from "../../@types/DTO/AppSetting";
import { PrivacyPolicy } from "../../@types/DTO/privacyPolicy";
import { AppRadioButton } from "../../components/UI/AppRadioButtons/AppRadioButtons";
import i18n from "../../config/i18n";

function TermsAndConditions({
  updateSelectedPrivacyPolicy,
  selectedPrivacyPolicy,
  allPrivacyPolicy,
  requiredPolicy,
}) {
  const [showTermCondition, setShowTermCondition] = useState(false);
  const toogleForTermCondition = () => {
    setShowTermCondition(!showTermCondition);
  };
  const textResource = BookingGetters.getTextResource();
  const data: TextResource[] = textResource?.data ?? [];
  const termsConditionAcceptText = data.find(
    (item) => item.key === "TermsConditionAcceptText"
  );
  const termsConditionAcceptBody = termsConditionAcceptText?.body;
  const { t: translate } = useTranslation();
  const [termCondition, setTermCondition] = useState<string>("");
  const appSettings = BookingGetters.getAppSettings();
  const fontFamilyStyle = appSettings?.fontFamily;
  const hidePrivacyPolicyButton = appSettings?.hidePrivacyPolicyButton;
  useEffect(() => {
    BookingApi.getPrivacyAndTerms().then((e) => {
      setTermCondition(e?.data?.conditionText);
    });
  }, []);

  return (
    <div className="flex flex-col bg-white box-border p-8">
      <div className="flex flex-row flex-wrap gap-8">
        <AppStandardButton
          onClick={() => {
            BookingApi.getPrivacyAndTerms().then((e) => {
              const url = e.data.termsConditionFile;
              if (url.includes("s3.amazonaws")) {
                fetch(url).then(async (e) => {
                  const fileBlob = await e.blob();
                  const linkObj = document.createElement("a");
                  linkObj.href = URL.createObjectURL(fileBlob);
                  linkObj.target = "_blank";
                  linkObj.click();
                  return fileBlob;
                });
              } else {
                const linkObj = document.createElement("a");
                linkObj.href = url;
                linkObj.target = "_blank";
                linkObj.click();
              }
            });
          }}
          variant="contained"
          color="primary"
          style={{ borderRadius: "0" }}
          className="h-12 w-80 mt-8"
        >
          <span style={{ fontFamily: fontFamilyStyle }}>
            {t("Download terms and conditions")}{" "}
          </span>
        </AppStandardButton>
        {!hidePrivacyPolicyButton && (
          <AppStandardButton
            onClick={() => {
              BookingApi.getPrivacyAndTerms().then((e) => {
                const url = e.data.privacyfileUrl;
                console.log("url");
                if (url.includes("s3.amazonaws")) {
                  console.log("if");
                  fetch(url).then(async (e) => {
                    const fileBlob = await e.blob();
                    const linkObj = document.createElement("a");
                    linkObj.href = URL.createObjectURL(fileBlob);
                    linkObj.target = "_blank";
                    linkObj.click();
                    return fileBlob;
                  });
                } else {
                  const linkObj = document.createElement("a");
                  linkObj.href = url;
                  linkObj.target = "_blank";
                  linkObj.click();
                }
              });
            }}
            variant="contained"
            color="primary"
            style={{ borderRadius: "0" }}
            className="h-12 w-81 mt-8"
          >
            <span style={{ fontFamily: fontFamilyStyle }}>
              {t("Download the privacy policy")}{" "}
            </span>
          </AppStandardButton>
        )}
      </div>

      {termsConditionAcceptBody != "" && termsConditionAcceptBody != null ? (
        <div
          className={"text-black text-base mt-2"}
          dangerouslySetInnerHTML={{ __html: termsConditionAcceptBody }}
        />
      ) : (
        <>
          {/* Render the checkbox first */}
          <AppStandardMultiSelectCheckBox
            required={true}
            color={"primary"}
            options={[
              {
                label: (
                  <>
                    <span>{t("I accept the")}</span>{" "}
                    <span
                      style={{ color: "primary" }}
                      onClick={toogleForTermCondition}
                      className="text-[primary] text-md font-bold"
                    >
                      {t("Terms and Conditions")}
                    </span>
                  </>
                ),
                value: "I have read and agree to the rental conditions",
              },
            ]}
          />
          <p className="text-black text-base mt-2">
            {t("By clicking you're agreeing to our terms and conditions")}
          </p>
        </>
      )}

      {showTermCondition && termCondition.length > 0 && (
        <div className="bg-white box-border p-6 rounded-md shadow-md">
          <div
            className="text-[#707070] text-sm text-left whitespace-pre-line"
            dangerouslySetInnerHTML={{ __html: termCondition }}
          />
        </div>
      )}

      {appSettings?.showMyRentPolicies && allPrivacyPolicy && (
        <div className="bg-white box-border p-3 rounded-md shadow-md mt-6">
          <span className="text-[#707070] text-sm text-left whitespace-pre-line">
            {allPrivacyPolicy.map((e, index) => {
              const optionsWithAsterisk = e
                ?.map((item) => {
                  const value =
                    i18n.language == "it"
                      ? item.privacyPolicyLocalLang
                      : item.privacyPolicyEn || "";
                  if (
                    Array.isArray(requiredPolicy) &&
                    requiredPolicy.length > 0
                  ) {
                    return requiredPolicy.includes(value) ? `*${value}` : value;
                  }
                  return value;
                })
                .filter((value) => value !== "");
              (item) => !!item;
              const isRequired = e.map((item) => {
                const value =
                  i18n.language == "it"
                    ? item.privacyPolicyLocalLang
                    : item.privacyPolicyEn || "";
                return requiredPolicy.includes(value) ? true : false;
              });
              return (
                <div key={index} className="mb-4">
                  <span className="text-md text-black font-bold">
                    Privacy policy {index + 1}
                  </span>
                  <br />
                  <AppStandardMultiSelectCheckBox
                    required={isRequired}
                    color={"primary"}
                    value={selectedPrivacyPolicy[index]}
                    options={optionsWithAsterisk}
                    onChange={(value) => {
                      updateSelectedPrivacyPolicy(index, value);
                    }}
                  />
                </div>
              );
            })}
          </span>
        </div>
      )}
    </div>
  );
}
export default TermsAndConditions;
