import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { ForwardedRef, forwardRef, useState } from "react";

import { AppRadioButtonProps } from "./AppRadioButtons.props";
import { AppRadioButtonStyles } from "./AppRadioButton.styles";
import React from "react";
import { OptionsI } from "../../../@types/FormFields";
import BookingGetters from "../../../store/onlineBooking/getters";

const AppRadioButton = forwardRef(function AppRadioButton(
  {
    label,
    value,
    options = [],
    required = false,
    onChange = () => {},
    readOnly = false,
    isInRow = true,
    className = "",
    color = "",
    ...otherProps
  }: AppRadioButtonProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const StyleClasses = AppRadioButtonStyles();
  const [hasSeenTheOption, setHasSeenTheOption] = useState<boolean>(false);
  const appSettings = BookingGetters.getAppSettings();
  const fontFamilyStyle = appSettings?.fontFamily;

  return (
    <div className={className}>
      <FormControl
        fullWidth
        className={StyleClasses.default}
        // component="fieldset"
      >
        <FormLabel>
          {label}
          {required && label && <span className="text-red-600"> *</span>}
        </FormLabel>
        <RadioGroup
          row={isInRow}
          onChange={(e, value) => {
            onChange(value, e);
          }}
          {...otherProps}
          value={value || ""}
        >
          {options.map((el: string | OptionsI, index) => {
            let label: string = el.toString();
            let optionValue: string = el.toString();
            if (typeof el !== "string") {
              label = el.label;
              optionValue = el.value?.toString() || el.label;
            }
            if (!hasSeenTheOption && optionValue == value) {
              setHasSeenTheOption(true);
            }
            return (
              <FormControlLabel
                name={label}
                disabled={readOnly}
                value={optionValue}
                control={
                  <Radio
                    required={
                      (value != "false" && value != "") || hasSeenTheOption
                        ? false
                        : required
                    }
                    inputRef={ref}
                    color={color}
                  />
                }
                label={
                  <span style={{ fontFamily: fontFamilyStyle }}>{label}</span>
                }
                key={index}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </div>
  );
});
const AppSeparatedRadioButton = forwardRef(function AppSeparatedRadioButton(
  { label, options, required, readOnly = false }: AppRadioButtonProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const StyleClasses = AppRadioButtonStyles();
  return (
    <FormControl fullWidth className={StyleClasses.separated}>
      <FormLabel>{label}</FormLabel>
      <RadioGroup ref={ref}>
        {options.map((el, index) => {
          return (
            <FormControlLabel
              disabled={readOnly}
              value={el}
              control={<Radio required={required} />}
              label={el}
              key={index}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
});

export { AppRadioButton, AppSeparatedRadioButton };
